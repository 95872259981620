import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import InputComponent from "../../Inputs/InputComponent2/InputComponent2";
import { useForm } from "react-hook-form";

export const AddLevel = ({ visible, setVisible, levels, setLevels }) => {
	const {
		handleSubmit,
		formState: { errors },
		register,
		setValue,
	} = useForm();

	const onSubmit = (data) => {
		setLevels([
			...levels,
			{
				DurationVideos: +data?.DurationVideos,
				QuentityPerson: +data?.QuentityPerson,
				QuentityPublicityDay: +data?.QuentityPublicityDay,
				revenueLevel: +data?.revenueLevel,
			},
		]);
		setVisible(false);
	};

	return (
		<Modal
			key={levels?.length}
			title=""
			okButtonProps={{ hidden: true }}
			cancelButtonProps={{ hidden: true }}
			visible={visible}
			onCancel={() => setVisible(!visible)}
		>
			<form className="add-plan" onSubmit={handleSubmit(onSubmit)}>
				<InputComponent
					type="number"
					label="Segundos Obligatorio de videos"
					placeholder=""
					icon="fa fa-user-o"
					className="mb-10"
					error={errors.DurationVideos}
					errorMsg="Verifique el campo"
					{...register("DurationVideos", { required: true })}
				/>
				{/*         <InputComponent
          type="number"
          label="Cantiad de videos "
          placeholder=""
          icon="fa fa-user-o"
          className="mb-10"
          error={errors.QuantityVideos}
          errorMsg="Verifique el campo"
          {...register("QuantityVideos", { required: true })}
        /> */}
				{/* <InputComponent
          type="number"
          label="Personas Obligatorias"
          placeholder=""
          icon="fa fa-user-o"
          className="mb-10"
          error={errors.QuentityPerson}
          errorMsg="Verifique el campo"
          {...register("QuentityPerson", { required: true })}
        /> */}

				<InputComponent
					type="number"
					label="Cantidad de dìas:"
					placeholder=""
					icon="fa fa-user-o"
					className="mb-10"
					error={errors.QuentityPublicityDay}
					errorMsg="Verifique el campo"
					{...register("QuentityPublicityDay", { required: true })}
				/>
				<InputComponent
					type="number"
					label="Ganancia"
					placeholder=""
					icon="fa fa-user-o"
					className="mb-10"
					error={errors.revenueLevel}
					errorMsg="Verifique el campo"
					{...register("revenueLevel", { required: true })}
				/>

				<input type="submit" className="submit" value="Aceptar" />
			</form>
		</Modal>
	);
};
