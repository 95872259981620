import React, { useEffect, useCallback } from "react";
import { Button, Row, Col, notification } from "antd";
import jwtDecode from "jwt-decode";
import { Steps, Step } from "react-step-builder";
import { useParams, useHistory } from "react-router";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { Steps1 } from "../../../components/Steps/Steps1";
import { Steps2 } from "../../../components/Steps/Steps2";
import { Steps3 } from "../../../components/Steps/Steps3";
import { Loading } from "../../../components/Loading/Loading";
import { modalError } from "../../../components/SweetAlert/Error";
import { modalSuccess2 } from "../../../components/SweetAlert/Success2";

import Api from "../../../common/Api/Api";
import logo from "../../../assets/img/LogoWhite.png";

export const Register = () => {
	const {
		handleSubmit,
		formState: { errors },
		register,
		getValues,
		watch,
		setValue,
	} = useForm({ mode: "onSubmit" });

	const params = useParams();
	const dataUser = params?.token ? jwtDecode(params.token) : null;
	const history = useHistory();

	useEffect(() => {
		if (dataUser?.data) {
			const { identificacion, registerCode, nombre, email } =
				dataUser.data;
			setValue("identification", identificacion);
			setValue("registrationCode", registerCode);
			setValue("name", nombre);
			setValue("email", email);
		}
	}, [dataUser, setValue]);

	const openNotification = () => {
		notification.error({
			message: "Error de formulario",
			description:
				"Por favor verifique que todos los campos esten completos.",
		});
	};

	const fetchPositions = () =>
		fetch("https://countriesnow.space/api/v0.1/countries/positions").then(
			(res) => res.json()
		);
	const fetchStates = (country) =>
		fetch("https://countriesnow.space/api/v0.1/countries/states", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({ country }),
		}).then((response) => response.json());

	const fetchCities = (country, state) =>
		fetch("https://countriesnow.space/api/v0.1/countries/state/cities", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({ country, state }),
		}).then((response) => response.json());

	const { data: countries } = useQuery("countries", fetchPositions, {
		refetchOnWindowFocus: false,
	});

	const nationality = watch("nationality");
	const { data: state } = useQuery(
		["state", nationality],
		() => fetchStates(nationality),
		{
			refetchOnWindowFocus: false,
			enabled: !!nationality,
		}
	);

	const regionState = watch("state");
	const { data: city } = useQuery(
		["city", nationality, regionState],
		() => fetchCities(nationality, regionState),
		{
			refetchOnWindowFocus: false,
			enabled: !!regionState,
		}
	);

	const validateStep = useCallback(
		(step, fields) => {
			const values = fields.map((field) => watch(field));
			if (values.includes(undefined)) {
				openNotification();
				return false;
			}
			return true;
		},
		[watch, openNotification]
	);

	const onNextFirst = (props) => {
		const valid =
			props.current === 1
				? validateStep(props.current, [
						"datePerson",
						"direction",
						"phone",
						"banco",
						"TipoCuenta",
						"Numerocuenta",
				  ])
				: validateStep(props.current, [
						"nationality",
						"state",
						"municipaly",
						"scholarship",
						"profession",
						"civilStatus",
						"religion",
						"politicalTrend",
				  ]);
		if (valid) {
			props.next();
		}
	};

	const Navigation = (props) => (
		<Row align="left">
			{props.current !== props.allSteps.length - 2 && (
				<Col>
					<Button
						className="btn_accent_orange"
						onClick={props.prev}
						style={{ marginRight: 10 }}
					>
						Anterior
					</Button>
				</Col>
			)}
			{props.current !== props.allSteps.length ? (
				<Col>
					<Button
						className="btn_blue"
						onClick={() => onNextFirst(props)}
					>
						Siguiente
					</Button>
				</Col>
			) : (
				<Col>
					<Button className="btn_blue">
						<input
							type="submit"
							value="Guardar"
							Style="background: none;color: white;"
						></input>
					</Button>
				</Col>
			)}
		</Row>
	);

	const config = {
		navigation: {
			component: Navigation,
			location: "after",
		},
	};

	const mutation = useMutation((data) => Api.post("/person", data), {
		onSuccess: (data) => {
			if (data?.ok !== false) {
				modalError({
					message: data?.payload.message
						? data?.payload.message
						: "Revisa tus datos, por favor",
				});
			} else {
				if (data?.status === 200) {
					modalSuccess2({
						message:
							"Registrado con exito, tus credenciales fueron enviadas a tu correo electronico",
					});
				} else if (data?.status === 401) {
					modalError({
						message:
							"Tus credenciales han sido enviadas al correo registrado en IVO INTERNACIONAL, recuerda revisar en correo no deseado o spam",
					});
				} else if (data?.status === 400) {
					modalSuccess2({
						message: "La persona ya esta registrada",
					});
				}
			}
		},
		onError: (error) => {
			modalError({ message: "Parece que tenemos problemas" });
		},
	});

	const onSubmit = (data) => {
		const dataForm = {
			...data,
			referencePayment: dataUser?.data?.reference,
			idPlan: dataUser?.data?.idPlan,
		};
		mutation.mutate(dataForm);
	};

	return (
		<>
			<div className="register">
				<div className="register_logo">
					<img style={{ width: "60%" }} src={logo} alt="logo" />
				</div>
				<div className="register_content">
					<form onSubmit={handleSubmit(onSubmit)}>
						<Steps config={config}>
							<Step
								component={() => (
									<Steps1
										data={dataUser?.data}
										getValues={getValues}
										errors={errors}
										register={register}
									/>
								)}
							/>
							<Step
								component={() => (
									<Steps2
										countries={countries?.data}
										states={state?.data?.states}
										city={city?.data}
										getValues={getValues}
										errors={errors}
										register={register}
									/>
								)}
							/>
							<Step
								component={() => (
									<Steps3
										getValues={getValues}
										errors={errors}
										register={register}
										watch={watch}
									/>
								)}
							/>
						</Steps>
					</form>
				</div>
			</div>
			<Loading visible={mutation.isLoading} />
		</>
	);
};
