import React, { useEffect, useState } from "react";
import InputComponent from "../../components/Inputs/InputComponent2/InputComponent2";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import Api from "../../common/Api/Api";
import { modalError } from "../../components/SweetAlert/Error";
import { modalSucces } from "../../components/SweetAlert/Success";
import { BiArrowBack } from "react-icons/bi";
import { FaPlusCircle } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { AddLevel } from "../../components/Modals/Admin/AddLevel";

export const AddPlan = () => {
	const {
		handleSubmit,
		formState: { errors },
		register,
		getValues,
	} = useForm();

	const [levels, setLevels] = useState([]);
	const [visible, setVisible] = useState(false);

	const history = useHistory();

	const onSubmit = (data) => {
		console.log(levels.length);
		if (levels.length == 7) {
			mutation.mutate({ ...data, niveles: levels });
		} else {
			modalError({
				message: "Debes crear 7 niveles de manera obligatoria",
			});
		}
	};

	const mutation = useMutation(
		(dataPlan) => {
			return Api.post("/plan", dataPlan);
		},
		{
			onSuccess: (data) => {
				if (data?.ok === false) {
					modalError({
						message: data?.payload.message
							? data?.payload.message
							: "Revisa tus datos, por favor",
					});
				} else {
					modalSucces({
						message:
							"La petición se ha realizado de manera exitosa",
						url: "/plan",
					});
				}
			},
			onError: () => {
				modalError({ message: "Parece que tenemos problemas" });
			},
		}
	);

	return (
		<div className="Planusers">
			<BiArrowBack
				className="cursor-pointer"
				onClick={() => history.goBack()}
			/>
			<form
				className="add-plan pt-2 w-1/2 mx-auto"
				onSubmit={handleSubmit(onSubmit)}
			>
				<h1 className="font-bold text-gray-500 mb-10  ">Crear plan</h1>
				<InputComponent
					type="text"
					label="Nombre del plan"
					placeholder=""
					icon="fa fa-user-o"
					className="mb-10"
					error={errors.personPlanName}
					errorMsg="Verifique el campo"
					/*         defaultValue={} */
					{...register("personPlanName", { required: true })}
				/>
				<InputComponent
					type="number"
					label="Costo"
					placeholder=""
					icon="fa fa-user-o"
					className="mb-10"
					error={errors.personPlanCost}
					errorMsg="Verifique el campo"
					{...register("personPlanCost", { required: true })}
				/>
				<div className="flex">
					<h1 className="font-bold text-gray-500 mb-4 mr-4  ">
						Niveles
					</h1>
					<FaPlusCircle
						className="text-xl text-purple-800 cursor-pointer"
						onClick={() => setVisible(true)}
					/>
				</div>

				<div class=" flex flex-wrap">
					{levels?.length === 0 ? (
						<a
							onClick={() => setVisible(true)}
							href="#"
							className="block px-6 py-4 mr-4 mb-2 h-auto max-w-sm bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
						>
							<h5 className=" text-lg font-bold tracking-tight text-gray-900 dark:text-white">
								Es necesario agregar niveles
							</h5>
						</a>
					) : (
						levels.map((item, index) => (
							<a
								href="#"
								className="block px-6 py-4 mr-4 mb-2 h-auto max-w-sm bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
							>
								<h5 className=" text-lg font-bold tracking-tight text-gray-900 dark:text-white">
									Nivel {index + 1}
								</h5>
								<p className=" text-gray-700 dark:text-gray-400">
									{" "}
									<b> Segundos obligatorios: </b>{" "}
									{item?.DurationVideos}{" "}
								</p>
								<p className=" text-gray-700 dark:text-gray-400">
									{" "}
									<b> Cantidad días: </b>{" "}
									{item?.QuentityPublicityDay}{" "}
								</p>
								<p className=" text-gray-700 dark:text-gray-400">
									{" "}
									<b> Ganancia: </b> {item?.revenueLevel}{" "}
								</p>
							</a>
						))
					)}
				</div>
				<input type="submit" className="submit" value="Crear" />
			</form>
			{levels?.length < 7 ? (
				<AddLevel
					visible={visible}
					setVisible={setVisible}
					levels={levels}
					setLevels={setLevels}
				/>
			) : (
				<></>
			)}
		</div>
	);
};
