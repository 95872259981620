import React, { useEffect, useState } from "react";
import InputComponent from "../../components/Inputs/InputComponent2/InputComponent2";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import Api from "../../common/Api/Api";
import { modalError } from "../../components/SweetAlert/Error";
import { modalSucces } from "../../components/SweetAlert/Success";
import { useHistory } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { Loading } from "../../components/Loading/Loading";

export const AddPlanBusinesss = () => {
  const [dataPlanUser, setDataPlanUser] = useState([]);
  const [dataPlanUserDetail, setDataPlanUserDetail] = useState([]);
  const [IdPlanUserDetail, setIdPlanUserDetail] = useState(null);
  const [loading, setLoading] = useState(true);

  const { data: planCampaing } = useQuery("planCampaing", () =>
    Api.get("/plan")
  );

  const { data: dataPlanDetail, isLoading } = useQuery(
    ["planCampaingDetail", IdPlanUserDetail],
    () => Api.get(`/plan/detailPlan/${IdPlanUserDetail}`)
  );

  useEffect(() => {
    setDataPlanUserDetail(dataPlanDetail?.payload || []);
    setLoading(isLoading);
  }, [dataPlanDetail, IdPlanUserDetail, isLoading]);

  useEffect(() => {
    setDataPlanUser(planCampaing?.payload.items || []);
  }, [planCampaing]);

  const handleChange = (event) => {
    setIdPlanUserDetail(event.target.value || null);
  };

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm();

  const history = useHistory();

  const onSubmit = (data) => {
    mutation.mutate({ data });
  };

  const mutation = useMutation(
    (dataPlan) => {
      return Api.post("/plan/company", dataPlan);
    },
    {
      onSuccess: (data) => {
        if (data?.ok === false) {
          modalError({
            message: data?.payload.message
              ? data?.payload.message
              : "Revisa tus datos, por favor",
          });
        } else {
          modalSucces({
            message: "La petición se ha realizado de manera exitosa",
            url: "/planbusiness",
          });
        }
      },
      onError: () => {
        modalError({ message: "Parece que tenemos problemas" });
      },
    }
  );

  return (
    <div className="Planusers">
      <BiArrowBack
        className="cursor-pointer"
        onClick={() => history.goBack()}
      />
      <form
        className="add-plan pt-2 w-1/2 mx-auto"
        onSubmit={handleSubmit(onSubmit)}
      >
        <h1 className="font-bold text-gray-500 mb-10  ">Crear plan</h1>
        <InputComponent
          type="text"
          label="Nombre del plan"
          placeholder=""
          icon="fa fa-user-o"
          className="mb-10"
          error={errors.name}
          errorMsg="Verifique el campo"
          /*         defaultValue={} */
          {...register("name", { required: true })}
        />
        <InputComponent
          type="text"
          label="Descripción"
          placeholder=""
          icon="fa fa-user-o"
          className="mb-10"
          error={errors.description}
          errorMsg="Verifique el campo"
          {...register("description", { required: true })}
        />
        <InputComponent
          type="number"
          label="Número de personas"
          placeholder=""
          icon="fa fa-user-o"
          className="mb-10"
          error={errors.quentityPerson}
          errorMsg="Verifique el campo"
          {...register("quentityPerson", { required: true })}
        />

        <InputComponent
          type="number"
          label="Precio"
          placeholder=""
          icon="fa fa-user-o"
          className="mb-10"
          error={errors.cost}
          errorMsg="Verifique el campo"
          {...register("cost", { required: true })}
        />

        <InputComponent
          type="number"
          label="Tiempo limite (segundos)"
          placeholder=""
          icon="fa fa-user-o"
          className="mb-10"
          error={errors.cost}
          errorMsg="Verifique el campo"
          {...register("time", { required: true })}
        />

        <InputComponent
          select={true}
          label="Plan usuario"
          placeholder="Selecciona un plan"
          icon="fa fa-user-o"
          options={[{ isNewKey: true, name: 'Selecciona un plan', id: '' },
          ...dataPlanUser.map((item) => {
            return {
              isNewKey: true,
              name: item.personPlanName,
              id: item.idpersonPlan,
            };
          })]}
          className="mb-10"
          error={errors.plan}
          errorMsg="Verifique el campo"
          onChange={handleChange}
        />

        <InputComponent
          select={true}
          label="Nivel plan usuario"
          placeholder="Seleccione"
          icon="fa fa-user-o"
          options={[{ isNewKey: true, name: 'Selecciona un nivel', id: '' },
          ...dataPlanUserDetail.map((item) => {
            return {
              isNewKey: true,
              name: `Nivel: ${item.personPlanDetailLevel}, Dias publicidad: ${item.QuentityPublicityDay}, Cantidad personas: ${item.QuentityPerson}`,
              id: item.idpersonPlanDetail,
            };
          })]}
          className="mb-10"
          error={errors.nivel}
          errorMsg="Verifique el campo"
          {...register("nivel", { required: true })}
        />

        <input type="submit" className="submit" value="Crear" />
      </form>
      <Loading visible={loading} />
    </div>
  );
};
