import React, { useState } from "react";
import InputComponent from "../Inputs/InputComponent/InputComponent";
import { dummy } from "../../common/utils/Dummy/DummyRegister";

export const Steps2 = ({
	getValues,
	register,
	errors,
	countries,
	states,
	city,
}) => {
	const [aux, setAux] = useState(false);
	const [aux2, setAux2] = useState(false);

	const CustomInputComponent = ({
		label,
		name,
		options,
		aux,
		setAux,
		errorMsg = "Complete este campo",
		boris = false,
	}) => (
		<InputComponent
			type="text"
			label={label}
			placeholder=""
			icon="fa fa-user-o"
			className="mb-10"
			name={name}
			defaultValue={getValues(name)}
			{...register(name, { required: true })}
			error={errors[name]}
			errorMsg={errorMsg}
			select
			options={options}
			boris={boris}
			aux={aux}
			setAux={setAux}
		/>
	);

	return (
		<>
			<CustomInputComponent
				label="Nacionalidad"
				name="nationality"
				options={countries}
			/>
			<CustomInputComponent
				label="Departamento"
				name="state"
				options={states}
			/>
			<CustomInputComponent
				label="Ciudad"
				name="municipaly"
				options={city}
				boris={true}
			/>
			<CustomInputComponent
				label="Grado de escolaridad"
				name="scholarship"
				options={dummy.escolaridad}
			/>
			<CustomInputComponent
				label="Profesión"
				name="profession"
				options={dummy.profesion}
				aux={aux}
				setAux={setAux}
			/>
			{aux && (
				<CustomInputComponent
					label="Nueva Profesión"
					name="newProfesion"
					placeholder="¿Cual es su profesión?"
				/>
			)}
			<CustomInputComponent
				label="Estado Civil"
				name="civilStatus"
				options={dummy.estadocivil}
			/>
			<CustomInputComponent
				label="Religión"
				name="religion"
				options={dummy.religon}
				aux={aux2}
				setAux={setAux2}
			/>
			{aux2 && (
				<CustomInputComponent
					label="Nueva Religión"
					name="newReligion"
					placeholder="¿Que Religión?"
				/>
			)}
			<CustomInputComponent
				label="Tendencia Política"
				name="politicalTrend"
				options={dummy.tendenciapolitica}
			/>
		</>
	);
};
