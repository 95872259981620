import React, { useState } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { Button, Tabs } from "antd";
import { Progress } from "../../components/Modals/Company/progress";
import { AddCampaing } from "../../components/Modals/Admin/AddCampaing";
import { useMutation, useQuery } from "react-query";
import Api from "../../common/Api/Api";
import { Token } from "../../common/Storage/Token";
import jwt_decode from "jwt-decode";
import { WompiCampaign } from "../../components/Wompi/WompiCampaign";
import { MdDeleteSweep, MdOndemandVideo } from "react-icons/md";
import { BiEdit } from "react-icons/bi";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { UpdateCampaing } from "../../components/Modals/Admin/UpdateCampaing";
import { ViewCampaingMultimedia } from "../../components/Modals/Admin/ViewCampaingMultimedia";
import { modalError } from "../../components/SweetAlert/Error";
import { modalSucces } from "../../components/SweetAlert/Success";
import confirm from "antd/lib/modal/confirm";

const { TabPane } = Tabs;

export const CampaingCompany = () => {
  const { data, isLoading } = useQuery("campaign", () =>
    Api.get("/campaign/" + userData.data.idperson)
  );
  const dataToken = Token.decode();
  const [visible, setvisible] = useState(false);
  const [visibleAdd, setvisibleAdd] = useState(false);
  const [visibleEdit, setVisibleEdit] = useState();
  const [datacampanig, setdatacampanig] = useState();
  const [visibleMultimedia, setvisibleMultimedia] = useState(false);
  const userData = jwt_decode(Token.getToken());

  const handleDatacampanig = (da) => {
    setdatacampanig(da);
    setVisibleEdit(!visibleEdit);
  };

  const handleMultimedia = (id) => {
    setdatacampanig(id);
    setvisibleMultimedia(!visibleMultimedia);
  };

  function showConfirm(id) {
    confirm({
      title: "¿Ésta seguro que desea elimiar la campaña?",
      icon: <ExclamationCircleOutlined />,
      okText: "Si",
      cancelText: "No",
      onOk() {
        mutation.mutate(id);
      },
    });
  }

  const mutation = useMutation(
    (data) => {
      return Api.post("/campaign/delete/" + data);
    },
    {
      onSuccess: (data) => {
        if (data?.ok === false) {
          modalError({
            message: data?.payload.message
              ? data?.payload.message
              : "Revisa tus datos, por favor",
          });
        } else {
          modalSucces({
            message: "La petición se ha realizado de manera exitosa",
            reload: true,
          });
        }
      },
      onError: () => {
        modalError({ message: "Parece que tenemos problemas" });
      },
    }
  );

  const mapData = (item) => (
    <Tr>
      <Td>
        <div className="tdCustom">{item.publicityCampaignName}</div>
      </Td>
      <Td>
        <div className="tdCustom">{item.publicityCampaignDescription}</div>
      </Td>
      <Td>
        {item?.publicityCampaignPublished !== "I" ? (
          <div className="Icons">
            <Button
              className="btn_accent_blue"
              onClick={() => setvisible(!visible)}
            >
              Progreso
            </Button>
            <MdDeleteSweep
              onClick={() => showConfirm(item.idpublicityCampaign)}
              className="cursor-pointer"
            />
          </div>
        ) : (
          <div className="Icons">
            <br />
            <br />

            <BiEdit
              onClick={() => handleDatacampanig(item)}
              className="cursor-pointer"
            />

            <WompiCampaign
              campaign={item.idpublicityCampaign}
              value={item.cost}
              reference={item.referencePayment}
              isCompany={true}
            />

            <MdOndemandVideo
              className="cursor-pointer"
              onClick={() => handleMultimedia(item?.idpublicityCampaign)}
            />
          </div>
        )}
      </Td>
    </Tr>
  );
  return (
    <div className="campaing">
      <h3>Campañas</h3>
      <Button
        className="btn_accent_orange"
        onClick={() => setvisibleAdd(!visibleAdd)}
      >
        Agregar campaña
      </Button>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Pendientes de pago" key="1">
          <Table>
            <Thead>
              <Tr>
                <Th>Nombre</Th>
                <Th>Descripción</Th>
                <Th>Acciones</Th>
              </Tr>
            </Thead>
            <Tbody>
              {Array.isArray(data?.payload) &&
                data?.payload?.map(
                  (item, index) =>
                    item.publicityCampaignPublished == "I" && mapData(item)
                )}
            </Tbody>
          </Table>
        </TabPane>
        <TabPane tab="Campañas Activas" key="2">
          <Table>
            <Thead>
              <Tr>
                <Th>Nombre</Th>
                <Th>Descripción</Th>
                <Th>Multimedia</Th>
                <Th>Estado</Th>
                <Th>Acciones</Th>
              </Tr>
            </Thead>
            <Tbody>
              {Array.isArray(data?.payload) &&
                data?.payload?.map(
                  (item, index) =>
                    item.publicityCampaignPublished === "A" && mapData(item)
                )}
            </Tbody>
          </Table>
        </TabPane>
      </Tabs>

      <Progress visible={visible} setVisible={setvisible} />
      <AddCampaing
        visible={visibleAdd}
        setVisible={setvisibleAdd}
        campaing={data}
      />
      <UpdateCampaing
        key={datacampanig?.idpersonPlan}
        campaign={datacampanig}
        visible={visibleEdit}
        setVisible={setVisibleEdit}
      />
      <ViewCampaingMultimedia
        key={datacampanig}
        campaign={datacampanig}
        visible={visibleMultimedia}
        setVisible={setvisibleMultimedia}
      />
    </div>
  );
};
