import React, { useState } from 'react'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import { Button, Tabs, Pagination } from 'antd'
import { AddCampaing } from '../../components/Modals/Admin/AddCampaing'
import { MdDeleteSweep, MdOndemandVideo } from 'react-icons/md'
import { BiEdit } from 'react-icons/bi'
import { useMutation, useQuery } from 'react-query'
import { UpdateCampaing } from '../../components/Modals/Admin/UpdateCampaing'
import Api from '../../common/Api/Api'
import { modalError } from '../../components/SweetAlert/Error'
import { modalSucces } from '../../components/SweetAlert/Success'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import confirm from 'antd/lib/modal/confirm'
import { ViewCampaingMultimedia } from '../../components/Modals/Admin/ViewCampaingMultimedia'
import { onChange } from '../../common/utils/setPage'
import { Loading } from '../../components/Loading/Loading'
import InputComponent from '../../components/Inputs/InputComponent2/InputComponent2'
import { WompiCampaign } from '../../components/Wompi/WompiCampaign'

const { TabPane } = Tabs

export const Campaing = () => {
  const [visible, setvisible] = useState(false)
  const [visibleEdit, setVisibleEdit] = useState()
  const [datacampanig, setdatacampanig] = useState()
  const [visibleMultimedia, setvisibleMultimedia] = useState(false)
  const [currentPage, setcurrentPage] = useState(1)
  const { data, isLoading } = useQuery(['campaign', currentPage], () =>
    Api.get('/campaign', { page: currentPage })
  )

  const { data: publicated } = useQuery(
    ['campaignPublicates', currentPage],
    () => Api.get('/campaign/published', { page: currentPage })
  )
  const [searchField, setSearchField] = useState('')

  const handleDatacampanig = (da) => {
    setdatacampanig(da)
    setVisibleEdit(!visibleEdit)
  }

  const handleMultimedia = (id) => {
    setdatacampanig(id)
    setvisibleMultimedia(!visibleMultimedia)
  }

  function showConfirm(id) {
    confirm({
      title: '¿Ésta seguro que desea elimiar la campaña?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Si',
      cancelText: 'No',
      onOk() {
        mutation.mutate(id)
      },
    })
  }

  const mutation = useMutation(
    (data) => {
      return Api.post('/campaign/delete/' + data)
    },
    {
      onSuccess: (data) => {
        if (data?.ok === false) {
          modalError({
            message: data?.payload.message
              ? data?.payload.message
              : 'Revisa tus datos, por favor',
          })
        } else {
          modalSucces({
            message: 'La petición se ha realizado de manera exitosa',
            reload: true,
          })
        }
      },
      onError: () => {
        modalError({ message: 'Parece que tenemos problemas' })
      },
    }
  )

  const mapData = (item, publicityCampaignPublished) => (
    <Tr>
      <Td>
        <div className="tdCustom">{item.companyName}</div>
      </Td>
      <Td>
        <div className="tdCustom">{item.publicityCampaignName}</div>
      </Td>
      <Td>
        <div className="tdCustom">{item.publicityCampaignDescription}</div>
      </Td>
      <Td>
        <div className="Icons">
          <br />
          <br />

          {publicityCampaignPublished !== 'publicated' && (
            <BiEdit
              onClick={() => handleDatacampanig(item)}
              className="cursor-pointer"
            />
          )}
          <MdDeleteSweep
            onClick={() => showConfirm(item.idpublicityCampaign)}
            className="cursor-pointer"
          />
          {publicityCampaignPublished !== 'publicated' && (
            <WompiCampaign
              campaign={item.idpublicityCampaign}
              value={item.cost}
              reference={item.referencePayment}
            />
          )}
          <MdOndemandVideo
            className="cursor-pointer"
            onClick={() => handleMultimedia(item?.idpublicityCampaign)}
          />
        </div>
      </Td>
    </Tr>
  )

  const filterCampaing = (e) => {
    if (data?.payload) {
      let filterData = e.target.value
      const filtered = data?.payload?.items.filter((item) => {
        return item?.companyName
          .toLowerCase()
          .includes(filterData.toLowerCase())
      })
      setSearchField(filtered)
    }
  }

  return (
    <div className="campaing">
      <h3>Campañas</h3>
      <Button
        className="btn_accent_orange"
        onClick={() => setvisible(!visible)}
      >
        Agregar campaña
      </Button>
      <div className="header_title">
        <InputComponent
          onChange={(e) => filterCampaing(e)}
          placeholder="Buscar"
        />
      </div>

      <Tabs defaultActiveKey="1">
        <TabPane tab="Pendientes de pago" key="1">
          <Table>
            <Thead>
              <Tr>
                <Th>Nombre Empresa</Th>
                <Th>Nombre Campaña</Th>
                <Th>Descripción</Th>
                <Th>Acciones</Th>
              </Tr>
            </Thead>
            <Tbody>
              {searchField !== ''
                ? searchField.map(
                    (item) =>
                      item.publicityCampaignPublished !== 'I' &&
                      mapData(item, item.publicityCampaignPublished)
                  )
                : Array.isArray(data?.payload?.items) &&
                  data?.payload?.items.map((item) => mapData(item))}
            </Tbody>
          </Table>
        </TabPane>

        <TabPane tab="Campañas activas" key="2">
          <Table>
            <Thead>
              <Tr>
                <Th>Nombre Empresa</Th>
                <Th>Nombre Campaña</Th>
                <Th>Descripción</Th>
              </Tr>
            </Thead>
            <Tbody>
              {searchField !== ''
                ? searchField.map(
                    (item) =>
                      item.publicityCampaignPublished !== 'I' && mapData(item)
                  )
                : Array.isArray(publicated?.payload?.items) &&
                  publicated?.payload?.items.map((item) =>
                    mapData(item, 'publicated')
                  )}
            </Tbody>
          </Table>
        </TabPane>
      </Tabs>

      <div className="flex justify-center w-full absolute bottom-8 ">
        <Pagination
          onChange={(page) => onChange(page, setcurrentPage)}
          className="pagination"
          defaultCurrent={1}
          total={publicated?.payload?.pages * 10}
        />
      </div>
      <AddCampaing visible={visible} setVisible={setvisible} campaing={data} />
      <UpdateCampaing
        key={datacampanig?.idpersonPlan}
        campaign={datacampanig}
        visible={visibleEdit}
        setVisible={setVisibleEdit}
      />
      <ViewCampaingMultimedia
        key={datacampanig}
        campaign={datacampanig}
        visible={visibleMultimedia}
        setVisible={setvisibleMultimedia}
      />
      <Loading visible={isLoading} />
    </div>
  )
}
