import React from 'react';
import { Modal } from 'antd';
import InputComponent from '../../Inputs/InputComponent2/InputComponent2';
import { useForm } from 'react-hook-form';
import Api from '../../../common/Api/Api';
import { modalError } from '../../SweetAlert/Error';
import { modalSucces } from '../../SweetAlert/Success';
import { useMutation } from 'react-query';
import { Loading } from '../../Loading/Loading';

export const EditPlanBusiness = ({ visible, setVisible, data }) => {
  const mutation = useMutation(
    (dataPlan) => {
      return Api.put('/plan/company/' + data?.idplan_company, dataPlan);
    },
    {
      onSuccess: (dataPlan) => {
        if (dataPlan?.ok === false) {
          modalError({
            message: dataPlan?.payload.message
              ? dataPlan?.payload.message
              : 'Revisa tus datos, por favor'
          });
        } else {
          modalSucces({ message: 'Datos actualizados', reload: true });
        }
      },
      onError: () => {
        modalError({ message: 'Parece que tenemos problemas' });
      }
    }
  );
  const {
    formState: { errors },
    register,
    handleSubmit
  } = useForm();

  const onSubmit = async (e) => {
    mutation.mutate({
      name: e.name || data?.name,
      description: e.description || data?.description,
      quentityPerson: e.quentityPerson || data?.quentityPerson,
      cost: e.cost || data?.cost,
      state: 'A'
    });
  };
  return (
    <Modal
      title=""
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
      visible={visible}
      onCancel={() => setVisible(!visible)}
    >
      <form className="add-plan" onSubmit={handleSubmit(onSubmit)}>
        <InputComponent
          type="text"
          label="Nombre del plan"
          placeholder=""
          icon="fa fa-user-o"
          className="mb-10"
          error={errors.name}
          errorMsg="Verifique el campo"
          defaultValue={data?.name}
          {...register('name')}
        />
        <InputComponent
          type="text"
          label="Descripción"
          placeholder=""
          icon="fa fa-user-o"
          className="mb-10"
          error={errors.description}
          errorMsg="Verifique el campo"
          defaultValue={data?.description}
          {...register('description')}
        />
        <InputComponent
          type="number"
          label="Número de personas"
          placeholder=""
          icon="fa fa-user-o"
          className="mb-10"
          error={errors.quentityPerson}
          errorMsg="Verifique el campo"
          defaultValue={data?.quentityPerson}
          {...register('quentityPerson')}
        />

        <InputComponent
          type="number"
          label="Precio"
          placeholder=""
          icon="fa fa-user-o"
          className="mb-10"
          error={errors.cost}
          errorMsg="Verifique el campo"
          defaultValue={data?.cost}
          {...register('cost')}
        />
        <input type="submit" className="submit" value="Aceptar" />
      </form>
      <Loading visible={mutation.isLoading} />
    </Modal>
  );
};
