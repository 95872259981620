export const dummy = {
  casa: [
    { id: 1, name: "propia" },
    { id: 2, name: "arrendada" },
    { id: 3, name: "familiar" },
  ],

  mascota: [
    { id: 1, name: "Gato" },
    { id: 2, name: "Perro" },
    { id: 3, name: "Pájaros" },
    { id: 4, name: "Conejos" },
    { id: 5, name: "Hámster" },
    { id: 6, name: "Peces" },
    { id: 7, name: "Otro" },
  ],
  ingresos: [
    { id: 1, name: "400.000 – 1'200.000 COP" },
    { id: 2, name: "1'201.000 – 1'800.000 COP" },
    { id: 3, name: "1'800.000 – 2'200.000  COP" },
    { id: 4, name: "2'201.000 – 2'800.000 COP" },
    { id: 5, name: "3'201.000 - 4'800.000 UDS" },
    { id: 6, name: "4'801.000 – 5'200.000 COP" },
    { id: 7, name: "5'201.000 en adelante" },
  ],
  option: [
    { id: 1, name: "Ejemplo 1" },
    { id: 2, name: "Ejemplo 2" },
    { id: 3, name: "Ejmeplo 3" },
  ],
  escolaridad: [
    { id: 1, name: "Primaria" },
    { id: 2, name: "Secundaria" },
    { id: 3, name: "Universitaria" },
    { id: 4, name: "Postgrado" },
  ],
  profesion: [
    { id: 1, name: "Abogado" },
    { id: 2, name: "Docente" },
    { id: 3, name: "Enfermera" },
    { id: 4, name: "Medico" },
    { id: 5, name: "Ingeniero" },
    { id: 6, name: "Contador" },
    { id: 7, name: "Administrador de Empresa" },
    { id: 8, name: "Psicólogo" },
    { id: 9, name: "Otro" },
  ],
  estadocivil: [
    { id: 1, name: "Soltero (a)" },
    { id: 2, name: "Casado (a)" },
    { id: 3, name: "Viudo (a)" },
    { id: 4, name: "Separado (a)" },
    { id: 4, name: "Unión Libre" },
  ],
  religon: [
    { id: 1, name: "católica" },
    { id: 2, name: "cristiana" },
    { id: 3, name: "budista" },
    { id: 4, name: "musulmán" },
    { id: 5, name: "hinduista" },
    { id: 6, name: "Otro" },
  ],

  tendenciapolitica: [
    { id: 1, name: "Derecha" },
    { id: 2, name: "Izquierda" },
    { id: 3, name: "Centro" },
  ],
  TpDocumentos: [
    { id: 1, name: "Cédula de Ciudadanía" },
    { id: 2, name: "Pasaporte" },
    { id: 3, name: "Cédula de Extranjería" },
  ],
  TpCuenta: [
    { id: 1, name: "Ahorros" },
    { id: 2, name: "Corriente" },
  ],
  sexo: [
    { id: 1, name: "heterosexual" },
    { id: 2, name: "bisexual" },
    { id: 3, name: "homosexual" },
    { id: 4, name: "transexual" },
    { id: 5, name: "asexual" },
    { id: 6, name: "pan sexual" },
    { id: 7, name: "antro sexual" },
    { id: 8, name: "metrosexual" },
  ],
};
