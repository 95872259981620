import React, { useEffect } from "react";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import Api from "../../common/Api/Api";

export const CampaignPaymentState = () => {
  const params = useParams();

  const mutation = useMutation((data) => {
    return Api.post("/campaign/publish/" + params.id, {
      referencePayment: data, //url service + Token user + token login
    });
  });

  useEffect(() => {
    mutation.mutate(params.reference);
  }, []);

  return (
    <div className="mx-auto my-auto block p-6 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md  dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
      <div className="bg-white p-6  md:mx-auto">
        <svg
          viewBox="0 0 24 24"
          className="text-green-600 w-16 h-16 mx-auto my-6"
        >
          <path
            fill="currentColor"
            d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
          ></path>
        </svg>
        <div className="text-center">
          <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">
            ¡Pago exitoso!
          </h3>
          <p className="text-gray-600 my-2">
            Ha completado el pago satisfactoriamente
          </p>
          <p>Ahora continua con el registro completo </p>
          <div className="py-10 text-center">
            <a
              href={`${params?.isComapny ? "/campaingCompany" : "/campaing"}  `}
              className="px-12 bg-indigo-600 hover:bg-indigo-500 text-white font-semibold py-3"
            >
              Campañas
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
