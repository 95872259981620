import React from 'react'
import { FirebaseProvider } from '@useweb/use-firebase'
import firebase from "firebase/compat/app";
import { getMessaging } from 'firebase/messaging';
import { firebaseConfig } from '../../common/utils/firebaseConfig'


const firebaseApp = firebase.initializeApp(firebaseConfig)
const messaging = getMessaging(firebaseApp)

const envIsDev = process.env.NODE_ENV === 'development'

const vapidKey = 'BDB4kdSBUN18Kaee_hG5GgePXVhIB66_KsgjchlA2Zt4a9vJiuX81hHMsTIsSAdO7EdJJujBYW9HwOQI7yRiugw' // vapidKey is required

export default function Firebase({ children }) {
  return (
    <FirebaseProvider
      firebaseConfig={firebaseConfig}
      firebaseApp={firebaseApp}
      envIsDev={envIsDev}
      messaging={messaging}
      messagingOptions={{
        vapidKey,
      }}
    >
      {children}
    </FirebaseProvider>
  )
}