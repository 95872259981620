import React, { useEffect, useState } from "react";
import { siteUrl } from "../../common/Config/Environments";
import crypto from "crypto";
import "./Wompi.scss";

export const WompiCampaign = ({ value, reference, campaign, isCompany }) => {
  const [signature, setSignature] = useState("");

  const generateSignature = () => {
    const data = `${reference}${
      value * 100
    }COPprod_integrity_OYEZ8VxDAoruWjIREKV80MJgKANarx0V`;
    const signature = crypto.createHash("sha256").update(data).digest("hex");
    setSignature(signature);
  };

  useEffect(() => {
    generateSignature();
  }, [generateSignature, signature]);
  return (
    <div className="wompiCampaign">
      <form action="https://checkout.wompi.co/p/" method="GET">
        <input
          type="hidden"
          name="public-key"
          value="pub_prod_p4lWdXJCK9k9Nhf6aCZOjkSo8RlUuXy4"
        />
        <input type="hidden" name="currency" value="COP" />
        <input type="hidden" name="amount-in-cents" value={value * 100} />
        <input type="hidden" name="reference" value={reference} />
        <input type="hidden" name="signature:integrity" value={signature} />
        <input
          type="hidden"
          name="redirect-url"
          value={`${siteUrl}/CampaignPaymentState/${reference && reference}/${
            campaign && campaign
          }/${isCompany && isCompany}`}
        />
        <button type="submit">Publicar</button>
      </form>
    </div>
  );
};
