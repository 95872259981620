import React from "react";

export const NodeUserMobile = ({ nodeData }) => {
  return (
    <div className="node-user cursor-pointer">
      <div className="fullname">{nodeData?.name}</div>
      <div className="flex justify-between items-center my-5">
        {/*         <div className="position">{nodeData?.reference}</div>
         */}{" "}
      </div>
    </div>
  );
};
