import React, { useState, useEffect } from "react";
import { Button, Pagination } from "antd";
import { useMutation, useQuery } from "react-query";
import Api from "../../common/Api/Api";
import { AddUser } from "../../components/Modals/Admin/AddUser";
import { EditUser } from "../../components/Modals/Admin/EditUser";
import { ViewsUser } from "../../components/Modals/Admin/ViewsUser";
import InputComponent from "../../components/Inputs/InputComponent2/InputComponent2";
import UserTable from "../../components/Modals/Admin/UserTable";
import { Loading } from "../../components/Loading/Loading";
import confirm from "antd/lib/modal/confirm";
import { modalError } from "../../components/SweetAlert/Error";
import { modalSucces } from "../../components/SweetAlert/Success";
import { ExclamationCircleOutlined } from "@ant-design/icons";

export const Users = () => {
  const [visible, setVisible] = useState(false);
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [visibleViewsUser, setVisibleViewsUser] = useState(false);
  const [dataUser, setDataUser] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchField, setSearchField] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const { data, isLoading, isError, error } = useQuery(
    ["Users", currentPage],
    () => Api.get("/person/users", { page: currentPage }),
    {
      keepPreviousData: true,
      onSuccess: (data) => {
        setFilteredData(data.payload.items);
      },
      onError: (err) => {
        modalError({ message: "Error al obtener los datos: " + err.message });
      },
    }
  );

  const mutation = useMutation((id) => Api.post(`/person/delete/${id}`), {
    onSuccess: (data) => {
      if (!data?.ok) {
        modalError({
          message: data?.payload?.message || "Revisa tus datos, por favor",
        });
      } else {
        modalSucces({
          message: "La petición se ha realizado de manera exitosa",
          reload: true,
        });
      }
    },
    onError: () => {
      modalError({ message: "Parece que tenemos problemas" });
    },
  });

  const handleDataUser = (user) => {
    setDataUser(user);
    setVisibleEdit(true);
  };

  const handleDataViewsUser = (user) => {
    setDataUser(user);
    setVisibleViewsUser(true);
  };

  const showConfirm = (id) => {
    confirm({
      title: "¿Está seguro que desea eliminar este usuario?",
      icon: <ExclamationCircleOutlined />,
      okText: "Sí",
      cancelText: "No",
      onOk() {
        mutation.mutate(id);
      },
    });
  };

  const filterCampaign = (e) => {
    const filterData = e.target.value.toLowerCase();
    setSearchField(filterData);
    if (data?.payload?.items) {
      const filtered = data.payload.items.filter(
        (item) =>
          item.name.toLowerCase().includes(filterData) ||
          item.email.toLowerCase().includes(filterData)
      );
      setFilteredData(filtered);
    }
  };

  useEffect(() => {
    if (!searchField) {
      setFilteredData(data?.payload?.items || []);
    } else {
      const filtered = data?.payload?.items.filter(
        (item) =>
          item.name.toLowerCase().includes(searchField.toLowerCase()) ||
          item.email.toLowerCase().includes(searchField.toLowerCase())
      );
      setFilteredData(filtered);
    }
  }, [data, searchField]);

  useEffect(() => {
    setSearchField(""); // Reiniciar el campo de búsqueda al cambiar de página
    setFilteredData([]); // Reiniciar los datos filtrados al cambiar de página
  }, [currentPage]);

  if (isError) {
    return <div>Error al cargar los datos: {error.message}</div>;
  }

  return (
    <div className="users">
      <h3>Usuarios</h3>
      <Button className="btn_accent_orange" onClick={() => setVisible(true)}>
        Agregar Usuario
      </Button>
      <div className="header_title">
        <InputComponent
          value={searchField}
          onChange={filterCampaign}
          placeholder="Buscar"
        />
      </div>
      <div className="users__content">
        <UserTable
          data={filteredData}
          handleEdit={handleDataUser}
          handleDelete={showConfirm}
          handleView={handleDataViewsUser}
        />
      </div>
      <div className="flex justify-center w-full absolute bottom-8">
        <Pagination
          current={currentPage}
          onChange={(page) => setCurrentPage(page)}
          className="pagination"
          defaultCurrent={1}
          total={data?.payload?.pages * 10}
        />
      </div>

      <AddUser visible={visible} setVisible={setVisible} />
      <EditUser
        key={dataUser?.idperson}
        user={dataUser}
        visible={visibleEdit}
        setVisible={setVisibleEdit}
      />
      <ViewsUser
        key={dataUser?.idperson}
        visible={visibleViewsUser}
        setVisible={setVisibleViewsUser}
        user={dataUser}
      />
      <Loading visible={mutation.isLoading || isLoading} />
    </div>
  );
};
