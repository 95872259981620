import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";
import API from "../../../common/Api/Api";

export const SimpleBarChart = ({ type, title }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [mainType, periodType, detailType] = type.split("_");

        let url;
        if (detailType) {
          url = `/statistics?type=${mainType}&period=${periodType}&detail=${detailType}`;
        } else {
          url = `/statistics?type=${mainType}&period=${periodType}`;
        }
        const response = await API.get(url);

        // Formatear las fechas usando moment.js
        const formattedData = response.payload.data.map((item) => ({
          ...item,
          date:
            periodType !== "year"
              ? moment(item.date).format("YYYY-MM-DD")
              : item.date,
        }));

        setData(formattedData);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, [type]);

  const chartStyles = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  };

  // Obtener las categorías únicas si existen
  const categories = [...new Set(data.map((item) => item.name))];

  return (
    <div style={chartStyles}>
      <h3>{title}</h3>
      <ResponsiveContainer width="95%" height={500}>
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" angle={-45} textAnchor="end" height={70} />
          <YAxis />
          <Tooltip />
          <Legend />
          {categories.length > 1 ? (
            categories.map((category, index) => (
              <Bar
                key={index}
                dataKey="value"
                fill={`#${Math.floor(Math.random() * 16777215).toString(16)}`}
                name={category}
              />
            ))
          ) : (
            <Bar dataKey="value" fill="#8884d8" name="Afiliados" />
          )}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
