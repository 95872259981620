import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import InputComponent from "../../Inputs/InputComponent2/InputComponent2";
import { useForm } from "react-hook-form";
import { message } from "antd";
import { useMutation, useQuery } from "react-query";
import Api from "../../../common/Api/Api";
import { modalError } from "../../SweetAlert/Error";
import { modalSucces } from "../../SweetAlert/Success";
import { UploadFirebase } from "../../firebase/PromiseUpload";
import { Loading } from "../../Loading/Loading";
import { chosenVideo } from "../../../common/utils/GetDuration";
import { RiDeleteBin6Line } from "react-icons/ri";
import jwt_decode from "jwt-decode";
import { Token } from "../../../common/Storage/Token";
import throat from "throat";
import Select from "react-select";
import { parse } from "dotenv";

export const AddCampaing = ({ visible, setVisible }) => {
	const [loading, setloading] = useState(false);
	const [duration, setDuration] = useState(null);
	const [video, setVideo] = useState([]);
	const [planSelected, setplanSelected] = useState(null);
	const [file, setFile] = useState(null);
	const [options, setoptions] = useState([]);
	const [fkCompany, setfkCompany] = useState();
	const userData = jwt_decode(Token.getToken());

	const mutation = useMutation(
		(data) => {
			return Api.post("/campaign", data);
		},
		{
			onSuccess: (data) => {
				if (data?.ok === true) {
					modalSucces({
						message:
							"La petición se ha realizado de manera exitosa",
						reload: true,
					});
				} else {
					modalError({
						message: data?.payload.message
							? data?.payload.message
							: "Revisa tus datos, por favor",
					});
				}
			},
			onError: () => {
				modalError({ message: "Parece que tenemos problemas" });
			},
		}
	);

	const { data: planCampaing } = useQuery("planCampaing", () =>
		Api.get("/plan/company")
	);
	const { data: dataCompany } = useQuery("dataCompany", () =>
		Api.get("/company?page=1")
	);

	const {
		formState: { errors },
		register,
		handleSubmit,
		watch,
	} = useForm();

	const onSubmit = async (e) => {
		var items = [];
		setloading(true);
		const result = await Promise.all(
			video.map(
				throat(1, async (i) => {
					await UploadFirebase({ value: i })
						.then(async (res) => {
							items = [
								...items,
								{
									multimediaName: i.name,
									multimediaUrl: res,
								},
							];
						})
						.catch((error) => {
							console.error(error);
						});
				})
			)
		);

		mutation.mutate({
			campaign: {
				publicityCampaignName: e.publicityCampaignName,
				publicityCampaignDescription: e.publicityCampaignDescription,
				idcompanyFK:
					userData?.data.nameRole === "COMPANY"
						? +userData.data.idcompany
						: +fkCompany,
				plan: planSelected?.idplan_company,
			},
			multimedia: items,
		});
		setloading(false);
	};

	const deleteItem = (video, name) => {
		return video.filter((item) => item.name !== name);
	};

	const handleDelete = (name) => {
		setVideo(deleteItem(video, name));
	};

	var isVideoPreview = "";
	const onSelect = (e) => {
		setVideo([]);
		const idPlan = e.target.value;
		const user = planCampaing?.payload?.items?.find(
			(u) => parseInt(u.idplan_company) === parseInt(idPlan)
		);

		setplanSelected(user && user);
	};

	useEffect(() => {
		if (file && duration && planSelected) {
			if (duration > planSelected?.time) {
				message.error(
					` El tiempo maximo permitido por el plan es de ${planSelected?.time} segundos`
				);

				setFile(null);
				setDuration(null);

				video.pop();
			} else {
				if (!video.some((e) => e.name === file.name)) {
					setVideo([...video, file]);
				}
			}
		}
	}, [duration, file, planSelected, video]);

	const onChangeSelect = (e) => {
		setfkCompany(e.value);
	};

	useEffect(() => {
		var optionE = [];

		return dataCompany?.payload?.items.forEach((i) => {
			i.companyState !== "I" &&
				optionE.push({ value: i.idcompany, label: i.companyName });
			setoptions(optionE);
		});
	}, [dataCompany]);

	return (
		<Modal
			title=""
			visible={visible}
			okButtonProps={{ hidden: true }}
			cancelButtonProps={{ hidden: true }}
			onCancel={() => setVisible(!visible)}
		>
			<div>
				<div>{isVideoPreview}</div>
			</div>

			<form onSubmit={handleSubmit(onSubmit)} className="add-campaing">
				<div className="request-local_grid">
					<InputComponent
						type="text"
						label="Nombre de la campaña"
						placeholder=""
						icon="fa fa-user-o"
						className=""
						error={errors.publicityCampaignName}
						errorMsg="Verifique el campo"
						{...register("publicityCampaignName", {
							required: true,
						})}
					/>
					<InputComponent
						type="text"
						label="Descripción"
						placeholder=""
						icon="fa fa-user-o"
						className=""
						error={errors.publicityCampaignDescription}
						errorMsg="Verifique el campo"
						{...register("publicityCampaignDescription", {
							required: true,
						})}
					/>
				</div>

				{userData?.data.nameRole !== "COMPANY" && (
					<div className="flex flex-col items-left ">
						<span className="text-start w-full">
							Seleccione una empresa
						</span>
						<Select
							className="w-11/12"
							options={options && options}
							onChange={onChangeSelect}
						/>

						{/*       <div className="input-component2 w-11/12">
              <select {...register("idcompanyFK", { required: true })}>
                {Array.isArray(dataCompany?.payload?.items) &&
                  dataCompany?.payload?.items.map(
                    (i) =>
                      i.companyState !== "I" && (
                        <option id={i.idcompany} value={i.idcompany}>
                          {i.companyName}
                        </option>
                      )
                  )}
              </select>
            </div> */}
					</div>
				)}

				<span>Seleccione un plan</span>
				<div className="input-component2 w-11/12">
					<select
						defaultValue={
							planCampaing?.payload?.items[0]?.idplan_company
						}
						onChange={(e) => onSelect(e)}
					>
						<option>Seleccione un plan</option>
						{Array.isArray(planCampaing?.payload?.items) &&
							planCampaing?.payload?.items.map(
								(i) =>
									i.state !== "I" && (
										<option value={i.idplan_company}>
											{i.name}
										</option>
									)
							)}
					</select>
				</div>
				<div className="w-11/12 mt-2">
					{planSelected?.QuantityVideos !== video.length ? (
						<>
							<div className="flex justify-between">
								<span className="mb-10 w-full">
									Subir videos
								</span>
								{duration !== null && (
									<span className="mb-10 w-full">
										Duracion de videos: {duration}{" "}
									</span>
								)}
							</div>
							<div class="image-upload">
								<label className="add-file" for="file-input">
									Subir
								</label>
								<input
									className="w-full"
									id="file-input"
									type="file"
									accept="video/*"
									onChange={(e) =>
										chosenVideo(
											e,
											setDuration,
											duration,
											setFile,
											planSelected
										)
									}
								/>
							</div>
						</>
					) : (
						<span style={{ color: "coral" }}>
							Ha alcanzado el número máximo permitido para este
							plan{" "}
						</span>
					)}

					<div className="flex flex-col ">
						{video.map((item) => (
							<div className="flex w-2/4 items-center">
								<span className="item-row">{item?.name}</span>
								<RiDeleteBin6Line
									className="item-row iconDel"
									onClick={() => handleDelete(item?.name)}
								/>
							</div>
						))}
					</div>
				</div>
				<input type="submit" className="submit" value="Crear" />
			</form>
			<Loading visible={loading || mutation.isLoading} />
		</Modal>
	);
};
