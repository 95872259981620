import React, { useEffect, useState } from "react";
import InputComponent from "../../components/Inputs/InputComponent2/InputComponent2";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import Api from "../../common/Api/Api";
import { modalError } from "../../components/SweetAlert/Error";
import { modalSucces } from "../../components/SweetAlert/Success";
import { BiArrowBack } from "react-icons/bi";
import { FaPlusCircle } from "react-icons/fa";
import { useHistory, useParams } from "react-router-dom";
import { AddLevel } from "../../components/Modals/Admin/AddLevel";
import { EditLevel } from "../../components/Modals/Admin/EditLevel";

export const EditPlan = () => {
  const { handleSubmit, register, setValue } = useForm();
  const params = useParams();
  const [levels, setLevels] = useState([]);
  const [visible, setVisible] = useState(false);
  const [level, setLevel] = useState({});
  const [dataLoaded, setDataLoaded] = useState(false);

  const [visibleEdit, setVisibleEdit] = useState(false);
  const history = useHistory();

  const onSubmit = (data) => {
    console.log(data);
    console.log(levels);

    mutation.mutate({
      personPlanName: data?.personPlanName,
      personPlanCost: data?.personPlanCost,
      personPlanState: levels[0]?.personPlanState,
      niveles: levels,
    });
  };

  const { data: levelsDetail } = useQuery(["levels"], () =>
    Api.get("/plan/detailplan/" + params?.id)
  );

  const handleLevel = (item, index) => {
    setVisibleEdit(true);
    setLevel(item);
  };

  useEffect(() => {
    if (levelsDetail?.payload && !dataLoaded) {
      console.log(1);
      setLevels(levelsDetail.payload);
      setDataLoaded(true); // Marcar como cargados los datos
    }
  }, [levelsDetail, dataLoaded]);

  const mutation = useMutation(
    (dataPlan) => Api.put("/plan/" + params?.id, dataPlan),
    {
      onSuccess: (data) => {
        if (!data.ok) {
          modalError({
            message: data?.payload.message || "Revisa tus datos, por favor",
          });
        } else {
          modalSucces({
            message: "El plan se ha cambiado con éxito",
            url: "/plan",
          });
        }
      },
      onError: () => {
        modalError({ message: "Parece que tenemos problemas" });
      },
    }
  );
  useEffect(() => {
    setValue("personPlanName", levels && levels[0]?.personPlanName);
    setValue("personPlanCost", levels && levels[0]?.personPlanCost);
  }, [levels, setValue]);

  return (
    <div className="Planusers">
      <BiArrowBack
        className="cursor-pointer"
        onClick={() => history.goBack()}
      />
      <form
        className="add-plan pt-2 w-1/2 mx-auto"
        onSubmit={handleSubmit(onSubmit)}
      >
        <h1 className="font-bold text-gray-500 mb-10">Editar plan</h1>
        <InputComponent
          type="text"
          label="Nombre del plan"
          placeholder={levels[0]?.personPlanName}
          icon="fa fa-user-o"
          className="mb-10"
          /*         defaultValue={} */
          {...register("personPlanName")}
        />
        <InputComponent
          type="number"
          label="Costo"
          placeholder={levels[0]?.personPlanCost}
          icon="fa fa-user-o"
          className="mb-10"
          {...register("personPlanCost")}
        />

        <div className="flex">
          <h1 className="font-bold text-gray-500 mb-4 mr-4">Niveles</h1>
          <FaPlusCircle
            className="text-xl text-purple-800 cursor-pointer"
            onClick={() => setVisible(true)}
          />
        </div>

        <div className="flex flex-wrap">
          {Array.isArray(levels) && levels.length === 0 ? (
            <a
              onClick={() => setVisible(true)}
              href="#"
              className="block px-6 py-4 mr-4 mb-2 h-auto max-w-sm bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
            >
              <h5 className="text-lg font-bold tracking-tight text-gray-900 dark:text-white">
                Es necesario agregar niveles
              </h5>
            </a>
          ) : (
            Array.isArray(levels) &&
            levels.map((item, index) => (
              <a
                key={index}
                onClick={() => handleLevel(item, index)}
                href="#"
                className="block px-6 py-4 mr-4 mb-2 h-auto max-w-sm bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
              >
                <h5 className="text-lg font-bold tracking-tight text-gray-900 dark:text-white">
                  Nivel {index + 1}
                </h5>
                <p className="text-gray-700 dark:text-gray-400">
                  <b>Segundos obligatorios:</b> {item?.DurationVideos}
                </p>

                <p className="text-gray-700 dark:text-gray-400">
                  <b>Personas obligatorias:</b> {item?.QuentityPerson}
                </p>
                <p className="text-gray-700 dark:text-gray-400">
                  <b>Cantidad días:</b> {item?.QuentityPublicityDay}
                </p>
                <p className="text-gray-700 dark:text-gray-400">
                  <b>Ganancia:</b> {item?.revenueLevel}
                </p>
              </a>
            ))
          )}
        </div>
        <input type="submit" className="submit" value="Enviar" />
      </form>
      <EditLevel
        key={level?.idpersonPlanDetail}
        visible={visibleEdit}
        setVisible={setVisibleEdit}
        levels={levels}
        level={level}
        setLevel={setLevel}
        setLevels={setLevels}
      />
      {levels?.length < 7 ? (
        <AddLevel
          visible={visible}
          setVisible={setVisible}
          levels={levels}
          setLevels={setLevels}
        />
      ) : (
        <></>
      )}
    </div>
  );
};
