export const dummy = {
  DummyProgressCompany: [
    {
      id: 1,
      name_campaing: "IVO INTERNATIONAL",
      number_videos: 4,
      porcentage: "10%",
    },
    {
      id: 2,
      name_campaing: "IVO INTERNATIONAL",
      number_videos: 4,
      porcentage: "10%",
    },
    {
      id: 3,
      name_campaing: "IVO INTERNATIONAL",
      number_videos: 4,
      porcentage: "10%",
    },
    {
      id: 4,
      name_campaing: "IVO INTERNATIONAL",
      number_videos: 4,
      porcentage: "10%",
    },
  ],
};
