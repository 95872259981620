import React, { useState } from 'react';
import { Table, thead, Tbody, Tr, th, Td } from 'react-super-responsive-table';
import { Button, Tabs, Modal, Pagination } from 'antd';
import ReacthTMLTableToExcel from 'react-html-table-to-excel';
import { useMutation, useQuery } from 'react-query';
import Api from '../../common/Api/Api';
import { modalError } from '../../components/SweetAlert/Error';
import { modalSucces } from '../../components/SweetAlert/Success';
import confirm from 'antd/lib/modal/confirm';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import jwt_decode from 'jwt-decode';
import { Token } from '../../common/Storage/Token';
import { formatter } from '../../common/utils/formattPrice';
import { mapDataPaidOut } from './components/mapPadOut';

const { TabPane } = Tabs;

export const Payments = () => {
	const userData = jwt_decode(Token.getToken());

	const [visible, setVisible] = useState(false);
	const { data } = useQuery('payments', () => Api.get(`/payment`));
	const { data: paidOut } = useQuery('paidOut', () =>
		Api.get(`/payment/users`)
	);
	console.log(paidOut);
	const { data: excel } = useQuery('payExcel', () =>
		Api.get(`/payment/export`)
	);

	const showModal = () => {
		setVisible(!visible);
	};
	const handleCancel = () => {
		setVisible(!visible);
	};
	const handleOk = () => {
		setVisible(!visible);
	};

	const mutation = useMutation((data) => Api.put(`/payment/` + data), {
		onSuccess: (data) => {
			if (!data?.ok) {
				modalSucces({
					message: 'La peticion seha realizado de manera exitosa',
					reload: true,
				});
			} else {
				modalError({
					message: data?.payload?.message
						? data?.payload?.message
						: 'Revisar',
				});
			}
		},
		onError: () => {
			modalError({ message: 'Parece que tenemos problemas' });
		},
	});
	function showConfirm(id) {
		confirm({
			title: '¿Ésta seguro que desea realizar este pago?',
			icon: <ExclamationCircleOutlined />,
			okText: 'Si',
			cancelText: 'No',
			onOk() {
				mutation.mutate(id);
			},
		});
	}

	const mapData = (item, index) => (
		<Tr>
			<Td>
				<div className="tdCustom">{item?.nombre}</div>
			</Td>
			<Td>
				<div className="tdCustom">{item?.descripcion}</div>
			</Td>
			<Td>
				<div className="tdCustom">{item?.nivel}</div>
			</Td>
			<Td>
				<div className="tdCustom">
					{formatter.format(item?.cantidad)}
				</div>
			</Td>
			<Td>
				<div className="Icon">
					{item.pago !== 'SI' ? (
						<Button
							className="btn_accent_blue"
							onClick={() => showConfirm(item?.idpayment_users)}
						>
							Pagar
						</Button>
					) : (
						<></>
					)}
				</div>
			</Td>
		</Tr>
	);

	// const mapExcelHead = (item, index) => (
	// 	<Tr>
	// 		<Td>
	// 			<div className="tdCustom">901561117</div>
	// 		</Td>
	// 		<Td>
	// 			<div className="tdCustom">Pago a terceros</div>
	// 		</Td>
	// 		<Td>
	// 			<div className="tdCustom">Medio día</div>
	// 		</Td>
	// 		<Td>
	// 			<div className="tdCustom"></div>
	// 		</Td>
	// 		<Td>
	// 			<div className="tdCustom">46632393652</div>
	// 		</Td>
	// 		<Td>
	// 			<div className="tdCustom"> Ahorros</div>
	// 		</Td>
	// 		<Td>
	// 			<div className="tdCustom"></div>
	// 		</Td>
	// 	</Tr>
	// );
	const mapExcelBody = (item, index) => (
		<Tr>
			<Td>
				<div className="tdCustom">{item?.tipoDocumento}</div>
			</Td>
			<Td>
				<div className="tdCustom">{item?.identificacion}</div>
			</Td>
			<Td>
				<div className="tdCustom">{item?.nombre}</div>
			</Td>
			<Td>
				<div className="tdCustom">{item?.tipoTransaccion}</div>
			</Td>
			<Td>
				<div className="tdCustom">{item?.banco}</div>
			</Td>
			<Td>
				<div className="tdCustom">{item?.numeroCuenta}</div>
			</Td>
			<Td>
				<div className="tdCustom">{item?.correo}</div>
			</Td>
			<Td>
				<div className="tdCustom"></div>
			</Td>
			<Td>
				<div className="tdCustom"></div>
			</Td>
			<Td>
				<div className="tdCustom">{item?.telefono}</div>
			</Td>
			<Td>
				<div className="tdCustom">{item?.cantidad}</div>
			</Td>
			<Td>
				<div className="tdCustom">{item?.fecha.slice(0, 4)+item?.fecha.slice(5, 7)+item?.fecha.slice(8, 10)}</div>
			</Td>
		</Tr>
	);

	const mapDataPaidOut = (item, id) => {
		return (
			<Tr key={id}>
				<Td>
					<div className="tdCustom">{item?.identification}</div>
				</Td>
				<Td>
					<div className="tdCustom">{item?.name}</div>
				</Td>
				<Td>
					<div className="tdCustom">{item?.date.slice(0, 10)}</div>
				</Td>
				<Td>
					<div className="tdCustom">
						{formatter.format(item?.cost)}
					</div>
				</Td>
			</Tr>
		);
	};

	return (
		<div className="payments">
			<Tabs defaultActiveKey="1">
				<TabPane tab="Pagos" key="1">
					<Table>
						<thead>
							<Tr>
								<th>Nombre Usuario</th>
								<th>Descripción</th>
								<th>Nivel</th>
								<th>Valor</th>
							</Tr>
						</thead>
						<Tbody>
							{Array.isArray(data?.payload) &&
								data.payload.map(
									(item, index) =>
										item?.pago !== 'SI' &&
										mapData(item, index)
								)}
						</Tbody>
					</Table>
					<Table id="pagosPendientes">
						<Table>
							<thead className="thead">
								<tr>
									<th className=" hidden display:none">
										NIT PAGADOR
									</th>
									<th className=" hidden display:none">
										TIPO DE PAGO
									</th>
									<th className=" hidden display:none">
										APLICACIÓN
									</th>
									<th className=" hidden display:none">
										SECUENCIA DE ENVÍO
									</th>
									<th className=" hidden display:none">
										NRO CUENTA A DEBITAR
									</th>
									<th className=" hidden display:none">
										TIPO DE CUENTA A DEBITAR
									</th>
									<th className=" hidden display:none">
										DESCRIPCIÓN DEL PAGO
									</th>
								</tr>
							</thead>
							<Tbody className=" hidden display:none">
								<Tr>
									<Td>
										<div className="tdCustom">901561117</div>
									</Td>
									<Td>
										<div className="tdCustom">Pago a terceros</div>
									</Td>
									<Td>
										<div className="tdCustom">Medio día</div>
									</Td>
									<Td>
										<div className="tdCustom"></div>
									</Td>
									<Td>
										<div className="tdCustom">46632393652</div>
									</Td>
									<Td>
										<div className="tdCustom"> Ahorros</div>
									</Td>
									<Td>
										<div className="tdCustom"></div>
									</Td>
								</Tr>

								{/* {Array.isArray(excel?.payload) &&
									excel?.payload.map((item, index) =>
										mapExcelHead(item, index)
									)} */}
							</Tbody>
						</Table>
						<Table>
							<thead className="thead">
								<tr>
									<th className="hidden display-none">
										Tipo Documento
									</th>
									<th className=" hidden display:none">
										Nit Beneficiario
									</th>
									<th className=" hidden display:none">
										Nombre Beneficiario{' '}
									</th>
									<th className=" hidden display:none">
										Tipo Transaccion{' '}
									</th>
									<th className=" hidden display:none">
										Código Banco{' '}
									</th>
									<th className=" hidden display:none">
										Cuenta Beneficiario
									</th>
									<th className=" hidden display:none">
										Email
									</th>
									<th className=" hidden display:none">
										Documento Autorizado{' '}
									</th>
									<th className=" hidden display:none">
										Referencia
									</th>
									<th className=" hidden display:none">
										Celular Beneficiario
									</th>
									<th className=" hidden display:none">
										Valor Transaccion
									</th>
									<th className=" hidden display:none">
										Fecha de aplicación
									</th>
								</tr>
							</thead>
							<Tbody>
								<div className="hidden display:none">
									{Array.isArray(excel?.payload) &&
										excel.payload.map((item, index) =>
											mapExcelBody(item, index)
										)}
								</div>
							</Tbody>
						</Table>
					</Table>
					<div className="main-xls">
						<ReacthTMLTableToExcel
							id="botonExportarExcel"
							table="pagosPendientes"
							filename="Pagos Pendientes"
							sheet="pagina 1"
							buttonText="Descargar a XLS"
							className="xls"
						/>
					</div>
				</TabPane>

				<TabPane tab="Historial de Pagos">
					<Table id="historialPagos">
						<thead>
							<Tr>
								<th>Identificación</th>
								<th>Nombre Usuario</th>
								<th>Fecha de Pago</th>
								<th>Valor</th>
							</Tr>
						</thead>
						<Tbody>
							{Array.isArray(paidOut?.payload) &&
								paidOut?.payload.map((item, id) =>
									mapDataPaidOut(item, id)
								)}
						</Tbody>
					</Table>
					{/*        <div className="main-xls">
            <ReacthTMLTableToExcel
              id="botonExportarExcel"
              table="historialPagos"
              filename="Historial de pagos"
              sheet="pagina 1"
              buttonText="Descargar a XLS"
              className="xls"
            />
          </div> */}
				</TabPane>
			</Tabs>

			<Modal
				title="Realizar pago"
				visible={visible}
				onCancel={handleCancel}
				onOk={handleOk}
				okText="Si"
				cancelText="No"
				okButtonProps={{ hidden: false }}
				cancelButtonProps={{ hidden: false }}
			>
				<p>Seguro desea hacer este pago?</p>
			</Modal>
		</div>
	);
};
