import React, { useState } from "react";
import { siteUrl } from "../../common/Config/Environments";
import "./Wompi.scss";
import wompiLogo from "../../assets/img/wompi-logo.png";
import crypto from "crypto";
import { useEffect } from "react";

export const Wompi = ({ value, reference, token, redirect }) => {
  const [signature, setSignature] = useState("");

  const generateSignature = async () => {
    const data = `${reference}${
      value * 100
    }COPprod_integrity_OYEZ8VxDAoruWjIREKV80MJgKANarx0V`;
    const signature = crypto.createHash("sha256").update(data).digest("hex");
    setSignature(signature);
  };

  useEffect(() => {
    generateSignature();
  }, [generateSignature, signature]);

  return (
    <div className="wompi">
      <form action="https://checkout.wompi.co/p/" method="GET">
        <input
          type="hidden"
          name="public-key"
          value="pub_prod_p4lWdXJCK9k9Nhf6aCZOjkSo8RlUuXy4"
        />
        <input type="hidden" name="currency" value="COP" />
        <input type="hidden" name="amount-in-cents" value={value * 100} />
        <input type="hidden" name="reference" value={reference} />
        <input
          type="hidden"
          name="redirect-url"
          value={`${siteUrl}/paymentState/${token && token}/${
            redirect && redirect
          }`}
        />
        <input type="hidden" name="signature:integrity" value={signature} />

        <button type="submit">
          Donar con <img className="logo" src={wompiLogo} />
        </button>
      </form>
    </div>
  );
};
