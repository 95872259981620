import React from "react";
import InputComponent from "../Inputs/InputComponent/InputComponent";
import { Space } from "antd";
import { dummy } from "../../common/utils/Dummy/DummyRegister";
import Api from "../../common/Api/Api";
import { useQuery } from "react-query";
import SelectComponent from "./components/SelectComponent";

export const Steps1 = (props) => {
	
	const { data } = useQuery("bancos", () => Api.get("/utils/bancos"));

	const CustomInputComponent = ({
		label,
		type,
		name,
		className = "mb-10",
		max,
	}) => (
		<InputComponent
			type={type}
			label={label}
			placeholder=""
			icon="fa fa-user-o"
			className={className}
			defaultValue={props?.getValues(name)}
			{...props.register(name, { required: true })}
			error={props.errors[name]}
			errorMsg="Complete este campo"
			max={max}
		/>
	);

	return (
		<>
			<CustomInputComponent
				label="Nombre completo"
				type="text"
				name="name"
			/>
			<div className="flex justify-between mr-16 py-0 py-0">
				<CustomInputComponent
					label="Número de documento"
					type="number"
					name="identification"
					className="mb-12"
				/>
				<CustomInputComponent
					label="Código de Registro"
					type="text"
					name="registrationCode"
					className="mb-12"
				/>
			</div>
			<CustomInputComponent
				label="Correo Electrónico"
				type="email"
				name="email"
			/>
			<Space direction="vertical">
				<CustomInputComponent
					label="Fecha de nacimiento"
					type="date"
					name="datePerson"
				/>
			</Space>
			<CustomInputComponent
				label="Direccción"
				type="text"
				name="direction"
			/>
			<CustomInputComponent
				label="Número de celular"
				type="tel"
				name="phone"
				max="12"
			/>

			<SelectComponent
				label="Banco"
				name="banco"
				values={data?.payload}
				getValues={props.getValues}
				register={props.register}
			/>

			<SelectComponent
				label="Tipo de cuenta"
				name="TipoCuenta"
				values={dummy?.TpCuenta}
				getValues={props.getValues}
				register={props.register}
			/>

			<CustomInputComponent
				label="Número de Cuenta"
				type="number"
				name="Numerocuenta"
				className="mb-10 mt-2"
			/>
		</>
	);
};
