import React from "react";

const SelectComponent = ({ label, name, values, getValues, register }) => (
	<div className="mb-10">
		<label className="py-0 font-medium">{label}</label>
		<select
			className="tdCustom h-8 py-0 mb-2 flex justify-center"
			defaultValue={getValues(name)}
			{...register(name, { required: true })}
		>
			<option value="" disabled selected>
				Seleccione
			</option>
			{values?.map((value) => (
				<option
					key={value.idBanco || value.name}
					value={value.idBanco || value.name}
				>
					{value.Banco || value.name}
				</option>
			))}
		</select>
	</div>
);

export default SelectComponent;
