import React from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { BiEdit } from "react-icons/bi";
import { MdDeleteSweep } from "react-icons/md";
import { FiEye } from "react-icons/fi";
import { FaNetworkWired } from "react-icons/fa";
import { useHistory } from "react-router-dom";

const UserTable = ({ data, handleEdit, handleDelete, handleView }) => {
  const history = useHistory();

  const mapData = (item) => (
    <Tr key={item.idperson}>
      <Td>
        <div className="tdCustom">{item.name}</div>
      </Td>
      <Td>
        <div className="tdCustom">{item.personPlanName}</div>
      </Td>
      <Td>
        <div className="tdCustom">{item.referenceCode}</div>
      </Td>
      <Td>
        <div className="tdCustom">{item.email}</div>
      </Td>
      <Td>
        <div className="tdCustom">{item.phone}</div>
      </Td>
      <Td>
        <div className="tdCustom">{item.identification}</div>
      </Td>
      <Td>
        <div className="Icon">
          <BiEdit onClick={() => handleEdit(item)} className="cursor-pointer" />
          <MdDeleteSweep
            onClick={() => handleDelete(item.idperson)}
            className="cursor-pointer"
          />
          <FiEye onClick={() => handleView(item)} className="cursor-pointer" />
          <FaNetworkWired
            onClick={() =>
              history.push(
                `/progressuser/${item.idperson}/${item.referenceCode}`
              )
            }
            className="cursor-pointer"
          />
        </div>
      </Td>
    </Tr>
  );

  return (
    <Table>
      <Thead>
        <Tr>
          <Th>Nombre Usuario</Th>
          <Th>Plan</Th>
          <Th>Código Referencia</Th>
          <Th>Correo</Th>
          <Th>Teléfono</Th>
          <Th>Identificación</Th>
          <Th>Acciones</Th>
        </Tr>
      </Thead>
      <Tbody>
        {Array.isArray(data) &&
          data.map((item) => item.nameRole !== "ADMIN" && mapData(item))}
      </Tbody>
    </Table>
  );
};

export default UserTable;
