import React, { useState } from "react";
import { Tabs } from "antd";
import { SimpleBarChart } from "../../components/Recharts/SimpleBarChart/SimpleBarChart";
const { TabPane } = Tabs;

const chartTypes = [
  { type: "affiliates_day", title: "Afiliados por Día" },
  { type: "affiliates_month", title: "Afiliados por Mes" },
  { type: "affiliates_year", title: "Afiliados por Año" },
  { type: "unaffiliates_day", title: "Desafiliados por Día" },
  { type: "unaffiliates_month", title: "Desafiliados por Mes" },
  { type: "unaffiliates_year", title: "Desafiliados por Año" },
  {
    type: "affiliates_day_department",
    title: "Afiliados por Departamento (Día)",
  },
  {
    type: "affiliates_month_department",
    title: "Afiliados por Departamento (Mes)",
  },
  {
    type: "affiliates_year_department",
    title: "Afiliados por Departamento (Año)",
  },
  {
    type: "affiliates_day_city",
    title: "Afiliados por Ciudad y Municipios (Día)",
  },
  {
    type: "affiliates_month_city",
    title: "Afiliados por Ciudad y Municipios (Mes)",
  },
  { type: "affiliates_year_city", title: "Afiliados por Ciudad (Año)" },
  { type: "affiliates_day_age", title: "Afiliados por Rango de Edad (Día)" },
  { type: "affiliates_month_age", title: "Afiliados por Rango de Edad (Mes)" },
  { type: "affiliates_year_age", title: "Afiliados por Rango de Edad (Año)" },
  { type: "income_day", title: "Valor Ingreso por Afiliado (Día)" },
  { type: "income_month", title: "Valor Ingreso por Afiliado (Mes)" },
  { type: "income_year", title: "Valor Ingreso por Afiliado (Año)" },
  { type: "cancelled_day", title: "Valor Cancelado por Afiliado (Día)" },
  { type: "cancelled_month", title: "Valor Cancelado por Afiliado (Mes)" },
  { type: "cancelled_year", title: "Valor Cancelado por Afiliado (Año)" },
  { type: "affiliates_day_country", title: "Afiliados por País (Día)" },
  { type: "affiliates_month_country", title: "Afiliados por País (Mes)" },
  { type: "affiliates_year_country", title: "Afiliados por País (Año)" },
];

export const ChartsCompany = () => {
  return (
    <div className="charts-company">
      <Tabs defaultActiveKey="1" tabPosition="top" className="tabs">
        <TabPane tab="Día" key="1">
          <div className="charts-container">
            {chartTypes
              .filter((chart) => chart.type.includes("day"))
              .map((chart) => (
                <div key={chart.type} className="chart-card">
                  <SimpleBarChart type={chart.type} title={chart.title} />
                </div>
              ))}
          </div>
        </TabPane>
        <TabPane tab="Mes" key="2">
          <div className="charts-container">
            {chartTypes
              .filter((chart) => chart.type.includes("month"))
              .map((chart) => (
                <div key={chart.type} className="chart-card">
                  <SimpleBarChart type={chart.type} title={chart.title} />
                </div>
              ))}
          </div>
        </TabPane>
        <TabPane tab="Año" key="3">
          <div className="charts-container">
            {chartTypes
              .filter((chart) => chart.type.includes("year"))
              .map((chart) => (
                <div key={chart.type} className="chart-card">
                  <SimpleBarChart type={chart.type} title={chart.title} />
                </div>
              ))}
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
};
