import * as React from "react";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Demo } from "../../Demo/Demo";
import { Login } from "../../Auth/Login/Login";
import { Register } from "../../Auth/Register/Register";
import { Payment } from "../../Auth/Payment/Payment";
import { PaymentState } from "../../Auth/Payment/PaymentState";
import { ProgressMobile } from "../../Progress/progessUserMobile";

export const Public = () => {
  return (
    <Router>
      <Switch className="h-100">
        <Route exact path="/" component={Login} />
        <Route path="/register/:token" component={Register} />
        <Route path="/register" component={Register} />
        <Route exact path="/demo" component={Demo} />
        <Route exact path="/payment/:token" component={Payment} />
        <Route
          exact
          path="/paymentState/:token/:redirect"
          component={PaymentState}
        />
        <Route
          path="/progressusermobile/:userId/:id"
          component={ProgressMobile}
        />
      </Switch>
    </Router>
  );
};
