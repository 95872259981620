import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

import { firebaseConfig } from '../../common/utils/firebaseConfig';

initializeApp(firebaseConfig);

const messaging = getMessaging();

export const requestForToken = () => {
  return getToken(messaging, {
    vapidKey: `BDB4kdSBUN18Kaee_hG5GgePXVhIB66_KsgjchlA2Zt4a9vJiuX81hHMsTIsSAdO7EdJJujBYW9HwOQI7yRiugw`
  })
    .then((currentToken) => {
      if (currentToken) {
        return currentToken;
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        console.info(
          'No registration token available. Request permission to generate one.'
        );
      }
    })
    .catch((err) => {
      console.error('An error occurred while retrieving token. ', err);
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
