import React from "react";
import OrganizationChart from "@dabeng/react-orgchart";
import { NodeUserMobile } from "../../components/OrgChart/templateMobile";
import { users } from "../../common/utils/Dummy/DummyUsers";
import { useQuery } from "react-query";
import Api from "../../common/Api/Api";
import { Loading } from "../../components/Loading/Loading";
import { useParams } from "react-router-dom";
import "./ProgressMobile.css"; // Asegúrate de tener los estilos en un archivo CSS separado

export const ProgressMobile = () => {
  const { userId, id } = useParams();

  const { data, isLoading } = useQuery("progress", () =>
    Api.get(`/progress/${userId}/${id}`)
  );

  return (
    <>
      {isLoading ? (
        <Loading visible={isLoading} />
      ) : (
        <div className="progressUser">
          <OrganizationChart
            containerClass="no-border"
            chartClass="myChart"
            pan={true}
            zoom={false}
            zoomoutLimit={7}
            zoominLimit={0.5}
            datasource={
              data?.payload?.root !== null ? data?.payload?.root : users
            }
            NodeTemplate={NodeUserMobile}
          />
        </div>
      )}
    </>
  );
};
