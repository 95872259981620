import React from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export const modalSuccess2 = ({ message }) => {
	return MySwal.fire({
		icon: "success",
		title: <p>Felicitaciones</p>,
		heightAuto: "false",
		type: "success",
		customClass: "swal-height",
		text: message,
		confirmButtonText: '<i class="fa fa-thumbs-up"></i> Great!',
	});
};
