import React from 'react';

import { Wompi } from '../../../components/Wompi/Wompi';
import logo from '../../../assets/img/Logo.png';
import { useParams } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { formatter } from '../../../common/utils/formattPrice';

export const Payment = () => {
    const params = useParams();
    const { data } = params?.token ? jwtDecode(params.token) : null;
    const date = new Date();
    let output =
        String(date.getDate()).padStart(2, '0') +
        '/' +
        String(date.getMonth() + 1).padStart(2, '0') +
        '/' +
        date.getFullYear();

    return (
        <div className="payment">
            <div className="invoice-card">
                <img className="payment__logo" alt="logo" src={logo} />
                <div className="invoice-title">
                    <div id="main-title">
                        <h4>FACTURA DE DONACIÓN</h4>
                    </div>
                    <span id="date">{data?.reference}</span>
                    <span className="mt-10" id="date">
                        {data?.nombre}
                    </span>
                    <span id="date">{output}</span>
                </div>
                <div className="invoice-details">
                    <table className="invoice-table">
                        <thead>
                            <tr>
                                <td>Producto</td>
                                <td>Valor</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="row-data">
                                <td>{data?.descripcion}</td>
                                <td>{formatter.format(data?.costo)}</td>
                            </tr>
                            <tr className="calc-row">
                                <td>
                                    <b>Total</b>
                                </td>
                                <td>{formatter.format(data?.costo)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="invoice-footer">
                    <button className="">
                        <Wompi
                            token={params.token}
                            reference={data?.reference}
                            value={data?.costo}
                            redirect={data?.redirect}
                        />
                    </button>
                </div>
            </div>
        </div>
    );
};
