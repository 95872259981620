import { Modal } from 'antd';
import React from 'react';
import { useQuery } from 'react-query';
import Api from '../../common/Api/Api';
import { Loading } from '../../components/Loading/Loading';

export const DetailPlan = ({ visible, setVisible, data }) => {
  const { data: levels, isLoading } = useQuery(['levels', data], () =>
    Api.get('/plan/detailplan/' + data)
  );

  return (
    <Modal
      title="Niveles"
      visible={visible}
      onCancel={() => setVisible(!visible)}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
    >
      <div class=" flex flex-wrap">
        {levels?.payload?.map((item, index) => (
          <a
            href="#"
            className="block px-6 py-4 mr-4 mb-2 h-auto max-w-sm bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
          >
            <h5 className=" text-lg font-bold tracking-tight text-gray-900 dark:text-white">
              Nivel {index + 1}
            </h5>
            <p className=" text-gray-700 dark:text-gray-400">
              {' '}
              <b> Segundos obligatorios: </b> {item?.DurationVideos}{' '}
            </p>
            <p className=" text-gray-700 dark:text-gray-400">
              {' '}
              <b> Personas obligatorias: </b> {item?.QuentityPerson}{' '}
            </p>
            <p className=" text-gray-700 dark:text-gray-400">
              {' '}
              <b> Cantidad días: </b> {item?.QuentityPublicityDay}{' '}
            </p>
            <p className=" text-gray-700 dark:text-gray-400">
              {' '}
              <b> Ganancia: </b> {item?.revenueLevel}{' '}
            </p>
          </a>
        ))}
      </div>
      <Loading visible={isLoading} />
    </Modal>
  );
};
