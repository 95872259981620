import React from "react";
import { Modal } from "antd";
import InputComponent from "../../Inputs/InputComponent2/InputComponent2";
import { useForm } from "react-hook-form";

export const EditLevel = ({
  visible,
  setVisible,
  levels,
  setLevels,
  level,
}) => {
  const {
    handleSubmit,
    formState: { errors },
    register,
    setValue,
  } = useForm();

  const onSubmit = (data) => {
    const updatedLevel = { ...level, ...data };
    const updatedLevels = levels.map((lvl) =>
      lvl.idpersonPlanDetail === updatedLevel.idpersonPlanDetail
        ? updatedLevel
        : lvl
    );
    console.log(updatedLevels);
    setLevels(updatedLevels);
    setVisible(false);
  };

  return (
    <Modal
      key={levels?.length}
      title="Modificar Nivel"
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
      visible={visible}
      onCancel={() => setVisible(!visible)}
    >
      <form className="add-plan" onSubmit={handleSubmit(onSubmit)}>
        <InputComponent
          type="number"
          label="Segundos Obligatorio de videos"
          placeholder=""
          icon="fa fa-user-o"
          className="mb-10"
          defaultValue={level?.DurationVideos}
          error={errors.DurationVideos}
          errorMsg="Verifique el campo"
          {...register("DurationVideos")}
        />
        <InputComponent
          type="number"
          label="Personas Obligatorias"
          placeholder=""
          icon="fa fa-user-o"
          className="mb-10"
          value={level?.QuentityPerson}
          error={errors.QuentityPerson}
          errorMsg="Verifique el campo"
          {...register("QuentityPerson")}
        />
        <InputComponent
          type="number"
          label="Cantidad de días:"
          placeholder=""
          icon="fa fa-user-o"
          className="mb-10"
          defaultValue={level?.QuentityPublicityDay}
          error={errors.QuentityPublicityDay}
          errorMsg="Verifique el campo"
          {...register("QuentityPublicityDay")}
        />
        <InputComponent
          type="number"
          label="Ganancia"
          placeholder=""
          icon="fa fa-user-o"
          className="mb-10"
          defaultValue={level?.revenueLevel}
          error={errors.revenueLevel}
          errorMsg="Verifique el campo"
          {...register("revenueLevel")}
        />
        <input type="submit" className="submit" value="Aceptar" />
      </form>
    </Modal>
  );
};
